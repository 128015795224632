:root {
  --primary-color-bkg: #0275d8;
  --primary-color-font: snow;
  --danger-color-bkg: #d9534f;
  --danger-color-font: snow;
  --color-success: #14a44d;
  --light-grey: #e9ecef;
  --dark-grey: #292b2c;
  --custom-grey: #bfbfbf;
  --custom-blue: #02cdfc;
  font-size: 16px;
  --light-blue: #cdf5fe;
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 100;
  font-style: normal;
  background-color: #e9eff4;
  color: #333333;
}

.no-scroll {
  /* overflow: hidden; */
}

body main h1,
body main h2,
body main h3,
body main p {
  color: #333333;
}

.custom-blue-font {
  color: var(--custom-blue);
}

.custom-blue-btn {
  background-color: var(--custom-blue);
  color: snow;
}

.custom-blue-btn:hover {
  /* background-color: #019fc2; */
}

body {
  font-size: 1.2rem;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.App {
  text-align: center;
}

.bold {
  font-weight: bold;
}

button {
  background-color: none;
  border: none;
}

.btn-custom {
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
  font-size: 1.2rem;
}

.btn:hover {
  cursor: pointer;
}

.btn-primary-custom {
  background-color: var(--primary-color-bkg);
  color: var(--primary-color-font);
}

input {
  height: 1.5rem;
  font-size: 1.3rem;
  padding: 5px;
}

.small {
  font-size: 0.8rem;
}

.danger {
  background-color: var(--danger-color-bkg);
  color: var(--danger-color-font);
}

.form-group label,
.form-group input,
.form-group select {
  display: block;
}

.form-group select {
  margin: 0 auto;
  height: 30px;
  font-size: 18px;
}

.center {
  margin: 0 auto;
}

.center-t {
  text-align: center;
}

.w-half {
  width: 50%;
}

.incorrect-login {
  padding: 5px;
}

.admin-question-list-item {
  background-color: var(--light-grey);
  padding: 5px;
  margin: 5px 0;
}

.small-edit-link {
  text-align: right;
}

.margin-v {
  margin-top: 5px;
  margin-bottom: 5px;
}

.new-question-form {
  border: 10px solid var(--dark-grey);
  width: 80%;
  margin: 0 auto;
  background-color: lightyellow;
}

.navbar {
  background-color: var(--primary-color-bkg);
  color: var(--primary-color-font);
}

.navbar ul {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  padding: 5px 2rem;
  width: 100%;
}

.answer:hover {
  cursor: pointer;
}

.admin-answer {
  background-color: var(--light-grey);
  margin: 5px 0;
  padding: 10px 0 0 20px;
}

.admin-answers {
  width: 80%;
  margin: 0 auto;
}

.cols {
  display: flex;
}

.plus-button {
  background-color: var(--color-success);
  color: var(--danger-color-font);
}

.margin-b {
  margin-bottom: 10px;
}

.new-answer-form {
  background-color: lightgreen;
  padding: 10px;
  border: 10px solid black;
  border-radius: 20px;
}

.new-answer-form input {
  width: 100px !important;
}

.main-nav {
  background-color: #333333;
  color: white;
  height: 20px;
  padding: 20px 0;
  /* margin-bottom: 10px; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
}

.main-nav .logo {
  height: 30px;
  margin-left: 10px;
}

.progress-bar {
  width: 80%;
  padding: 7px;
  height: 20px;
  background-color: white;
  border-radius: 4px;
  margin: auto;
  /* border: 1px solid var(--custom-grey); */
}

.progress-bar-inner {
  background-color: var(--custom-blue);
  height: 12px;
  color: white;
  border-radius: 10px;
}

.progress-bar,
.progress-bar-inner {
  /* border-radius: 5px; */
}

.hover:hover {
  cursor: pointer;
}

.home-page {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  width: 100vw;
}

.home-page main {
  width: 80%;
  margin: 50px auto;
  /* border: 1px solid var(--custom-grey); */
  /* border-radius: 10px; */
  /* margin-top: 20px; */
  padding: 20px;
  padding-bottom: 90px;
  /* max-width: 800px; */
  background-color: #f5f6fa;
}

.home-page main h1 {
  font-size: 32px;
  margin: 0 auto;
  margin-bottom: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.answers-container {
  display: grid;
  flex-wrap: wrap;
  justify-content: space-around;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}

.answers-container .answer {
  border: 1px solid rgba(2, 205, 252, 1);
  box-sizing: border-box;
  /* border-radius: 10px; */
  margin: 5px 0;
  font-weight: 800;
  padding: 15px 20px;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 7rem;
  font-size: 1.3rem;
  width: 100%;
  background-color: #fbfcfc;
}

.answers-container .answer:last-child {
  justify-self: flex-end;
}

.home-page .nav-buttons {
  display: flex;
  position: relative;
  margin-top: 1.8rem;
  align-items: center;
  justify-content: center;
}

.nav-buttons div {
  padding: 8px 10px;
  border-radius: 3px;
  font-weight: bold;
  width: 35%;
}

.back-button {
  font-size: 1.4rem !important;
  color: var(--custom-blue);
}

.next-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* right: 0px; */
  height: 3rem;
  padding: 20px 5px;
  width: 100px;
  font-size: 2rem !important;
  margin-top: 70px;
  /* border-radius: 13px !important; */
}

.next-button::after {
  display: inline-block;
  margin-left: 10px;
  font-size: 35px;
  content: "→";
}

.next-button .large-arrow {
  font-size: 3rem !important;
  padding: 0 !important;
  margin: 0 !important;
}

.question-body {
  color: #5c5b5b;
  font-weight: 400;
}

.box-shadow {
  box-shadow: 10px 2px 5px var(--light-grey);
}

.box-shadow-2 {
  box-shadow: 2px 2px 5px var(--light-grey);
}

.box-shadow-3 {
  box-shadow: -4px 2px 5px var(--light-grey);
}

.border-bold {
  border-width: 2px !important;
}

.selected-answer {
  /* background-color: var(--light-blue); */
  /* border: 2px solid var(--custom-blue) !important; */
}

.question-page h2 {
  font-size: 1.7rem;
  margin: 30px auto;
  font-weight: normal;
  width: 100%;
  text-align: center;
}

.custom-input {
  height: 3rem;
  font-size: 1.5rem;
  text-align: center;
}

.text-answer {
  margin-top: -60px;
}

@media only screen and (max-width: 768px) {
  body,
  html {
    padding: 0;
    margin: 0;
    position: relative;
    height: 100%;
  }

  .question-page {
    height: 100%;
  }

  .answers-container {
    margin-top: -20px;
  }

  .answers-container .answer {
    font-size: 1rem;
    padding: 10px 15px;
    text-align: center;
    border: none;
  }

  .nav-buttons * {
    font-size: 1.5rem !important;
    width: 150px;
  }
  .home-page {
    position: relative;
    height: 100%;
  }

  .home-page main {
    border: none !important;
    box-shadow: none !important;
    margin: 0;
    padding: 10px 10px 500px 10px !important;
    text-align: center;
    width: 100%;
    height: 100% !important;
    /* background-color: red; */
  }

  .main-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    width: 100%;
  }

  .home-page main h1 {
    font-size: 1.8rem;
    margin: 0 auto;
    width: 100%;
  }

  .home-page main h2 {
    margin: 20px 0 35px 0 !important;
    font-size: 24px;
  }

  .home-page main {
  }

  .home-page .nav-buttons {
    position: relative;
    top: -20px;
  }
  .main-nav {
    position: relative;
    /* justify-content: center; */
  }

  .next-button {
    width: 150px;
    padding: 3px 10px !important;
    margin-top: 90px;
    box-sizing: content-box;
  }

  .custom-input {
    margin-top: 40px !important;
  }

  .selected-answer {
    background-color: white !important;
    border: none !important;
  }
}

.hidden-button {
  margin-top: 10px;
  float: left;
  position: absolute;
  right: 0;
  color: #333;
}

/* .profile-page input[type="submit"] {
  height: 30px !important;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
} */

@media (hover: hover) {
  .answer:hover {
    background-color: var(--light-blue) !important;
  }
}
